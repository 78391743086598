


























































import {
  defineComponent,
  ref,
  watch,
  onUnmounted,
  SetupContext,
} from "@vue/composition-api";
import {
  transformDate,
  transformTimeZone,
} from "@/core/transformers/sticker.transformer";
import { tabFocusService } from "@/core/services/tabFocus.service";
import { Subscription } from "rxjs";

export default defineComponent({
  name: "DatePicker",
  props: {
    id: String,
    ariaLabel: String,
    value: [Array, String],
    activePicker: String,
    label: String,
    allowedDates: Function,
    dayFormat: Function,
    headerDateFormat: Function,
    monthFormat: Function,
    hideDetails: Boolean,
    disabled: Boolean,
    events: [Array, Function, Object],
    eventColor: {
      type: [Array, Function, Object, String],
      default: () => "warning",
    },
    firstDayOfWeek: {
      type: [String, Number],
      default: 0,
    },
    elementRef: String,
    localeFirstDayOfYear: {
      type: [String, Number],
      default: 0,
    },
    max: String,
    min: String,
    multiple: Boolean,
    nextIcon: String,
    nextMonthAriaLabel: {
      type: String,
    },
    nextYearAriaLabel: {
      type: String,
    },
    pickerDate: String,
    prevIcon: {
      type: String,
    },
    prevMonthAriaLabel: {
      type: String,
    },
    prevYearAriaLabel: {
      type: String,
    },
    range: Boolean,
    reactive: Boolean,
    readonly: Boolean,
    scrollable: Boolean,
    showCurrent: {
      type: [Boolean, String],
      default: false,
    },
    selectedItemsText: {
      type: String,
    },
    showAdjacentMonths: Boolean,
    showWeek: Boolean,
    titleDateFormat: Function,
    type: {
      type: String,
      default: "date",
    },
    weekdayFormat: Function,
    yearFormat: Function,
    yearIcon: String,
    prependInnerIcon: String,
    prependIcon: String,
    color: String,
    flat: Boolean,
    noTitle: Boolean,
    filled: Boolean,
    loading: Boolean,
    formatDatePattern: String,
  },
  setup(
    props,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { emit, refs }: Omit<SetupContext, "refs"> & { [key: string]: any }
  ) {
    const tabFocusSubscription = new Subscription();
    const isOpen = ref(false);
    const innerValue = ref(transformDate(new Date(), "yyyy-MM-dd"));
    const dateValue = ref(transformDate(new Date(), "yyyy-MM-dd"));
    const datePattern = ref(props.formatDatePattern || "yyyy. MM. dd.");
    const isFocus = ref(false);
    watch(
      () => props.value,
      (newValue) => {
        innerValue.value = newValue as string;
        dateValue.value = transformDate(newValue as string, "yyyy-MM-dd");
      }
    );

    /* watch(
      () => isOpen.value,
      (value) => {
        let focusButton: HTMLElement;
        if (value) {
          setTimeout(() => {
            focusButton = refs.picker.$el.querySelectorAll(
              ".v-date-picker-header button"
            )[1] as HTMLElement;
            setTimeout(() => {
              focusButton.focus();
            }, 250);
          });
        }
      }
    );*/

    const onInput = (date: string) => {
      emit("input", transformTimeZone(date));
    };
    const onFocus = () => {
      isFocus.value = true;
    };
    const onBlur = () => {
      isFocus.value = false;
    };
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        isOpen.value = true;
      }
    };
    tabFocusSubscription.add(
      tabFocusService.getFocus().subscribe((target) => {
        if (target === props.elementRef) {
          const toValidityStartDatePickerRef =
            refs.toValidityStartDatePicker as HTMLElement;
          toValidityStartDatePickerRef.focus();
        }
      })
    );
    onUnmounted(() => {
      tabFocusSubscription.unsubscribe();
    });

    return {
      innerValue,
      dateValue,
      datePattern,
      isOpen,
      onInput,
      onFocus,
      onBlur,
      onKeydown,
    };
  },
});
