


































import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import StickerVehicleData from "@/views/StickerPurchasePage/components/StickerCreation/StickerVehicleData/StickerVehicleData.vue";
import StickerChoice from "@/views/StickerPurchasePage/components/StickerCreation/StickerChoice/StickerChoice.vue";
import { vehicleFacade } from "@/store/modules/vehicle/vehicle.facade";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import router from "@/router";
import isEmpty from "lodash/fp/isEmpty";

export default defineComponent({
  name: "StickerCreation",
  components: {
    StickerVehicleData,
    StickerChoice,
  },
  props: {
    newsletterPopup: Boolean,
  },
  setup(props, { emit }) {
    const routeQueryPage = router.app.$route.query?.page;
    const step = ref(0);
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const isChooseNewVehicle = ref(notLoggedInUser.value);
    const vehicleListLoading = computed(
      vehicleFacade.hdCustomerVehicleListLoading
    );
    const activeCartLoading = computed(stickerFacade.activeCartLoading);
    const checkStickerOverlapLoading = computed(
      stickerFacade.checkStickerOverlapLoading
    );
    const cartElementsLoading = computed(stickerFacade.cartElementsLoading);

    const changeStep = (stepNum: number) => {
      step.value = stepNum;
      emit("changeStep", step.value);
    };
    const chooseNewVehicle = (value: boolean) => {
      isChooseNewVehicle.value = value;
    };
    const deleteQueryParam = () => {
      if (!isEmpty(router.app.$route.query)) {
        router.replace({});
      }
    };
    onMounted(() => {
      if (!notLoggedInUser.value) {
        vehicleFacade.getHdCustomerVehicleList();
      }
      changeStep(routeQueryPage ? Number(routeQueryPage) : 1);
      emit("changeStep", step.value);
    });
    return {
      step,
      vehicleListLoading,
      isChooseNewVehicle,
      notLoggedInUser,
      changeStep,
      chooseNewVehicle,
      deleteQueryParam,
      activeCartLoading,
      checkStickerOverlapLoading,
      cartElementsLoading,
    };
  },
});
