






















































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import dialogService from "@/core/services/dialog.service";
import { vehicleFacade } from "@/store/modules/vehicle/vehicle.facade";
import { DialogResultType } from "@/core/dataTypes/types";
import { getVehiclePriceCategoryIcon } from "@/core/utils/vehicle";
import { i18n } from "@/locales/i18n";

export default defineComponent({
  name: "VehicleListItem",
  props: {
    vehicle: Object,
    modify: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    watch(
      () => props.vehicle,
      () => {
        isLocalLoading.value = false;
      }
    );
    const isLocalLoading = ref(false);
    const countries = computed(vehicleFacade.countries);
    const vehicleCategoryIcon = computed(
      () =>
        props.vehicle?.icon ||
        getVehiclePriceCategoryIcon(props.vehicle?.hdVehicleCategoryId)
    );
    const isoCode = computed(
      () =>
        props.vehicle?.isoCode ||
        countries.value?.find(
          (item) => item.countryCode === props.vehicle?.countryCode
        )?.isoCode
    );
    const setDefaultHdCustomerVehicleLoading = computed(
      vehicleFacade.setDefaultHdCustomerVehicleLoading
    );
    const clickDefault = () => {
      vehicleFacade.setDefaultHdCustomerVehicle(props.vehicle?.hdVehicleId);
      isLocalLoading.value = true;
    };
    const clickDelete = () => {
      dialogService
        .confirmDialog({
          maxWidth: 424,
          componentProps: {
            title: i18n.tc("VEHICLE_DELETE_DIALOG.TITLE"),
            content: i18n.tc("VEHICLE_DELETE_DIALOG.SUBTITLE", 1, {
              plateNumber: `<strong>${props.vehicle?.plateNumber}</strong>`,
            }),
            submitText: i18n.tc("VEHICLE_DELETE_DIALOG.BUTTONS.SUBMIT"),
            cancelText: i18n.tc("VEHICLE_DELETE_DIALOG.BUTTONS.CANCEL"),
          },
        })
        .$on("dialogResult", (result: DialogResultType): void => {
          if (result.payload) {
            vehicleFacade.deleteHdCustomerVehicle(props.vehicle?.hdVehicleId);
            isLocalLoading.value = true;
          }
        });
    };

    return {
      clickDefault,
      clickDelete,
      isLocalLoading,
      setDefaultHdCustomerVehicleLoading,
      vehicleCategoryIcon,
      isoCode,
    };
  },
});
