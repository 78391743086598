













































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import { validationRegExp } from "@/core/utils/validation/validationRegExp";
import AutocompleteInput from "@/components/forms/autocomplete/AutocompleteInput.vue";
import { vehicleFacade } from "@/store/modules/vehicle/vehicle.facade";
import TextInput from "@/components/forms/input/TextInput.vue";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";

export default defineComponent({
  name: "CheckPlateNumberDialog",
  components: {
    AutocompleteInput,
    BaseDialog,
    TextInput,
  },
  setup(props, ctx) {
    const countries = computed(vehicleFacade.countries);
    const hdVehicle = computed(stickerFacade.hdVehicle);
    const countryCode = ref("");
    const plateNumber = ref("");

    const wrongPlateNumber = ref(false);
    const wrongCounryCode = ref(false);
    const wrongPlateNumberAndCounryCode = ref(false);

    const checkPlateNumber = () => {
      wrongPlateNumber.value =
        hdVehicle.value?.countryCode === countryCode.value &&
        hdVehicle.value?.plateNumber != plateNumber.value;

      wrongCounryCode.value =
        hdVehicle.value?.countryCode != countryCode.value &&
        hdVehicle.value?.plateNumber === plateNumber.value;

      wrongPlateNumberAndCounryCode.value =
        hdVehicle.value?.countryCode != countryCode.value &&
        hdVehicle.value?.plateNumber != plateNumber.value;

      if (
        hdVehicle.value?.countryCode === countryCode.value &&
        hdVehicle.value?.plateNumber === plateNumber.value
      ) {
        ctx.emit("result", { autoClose: true, payload: "OK" });
      }
    };

    const closeDialog = () => {
      dialogCloseEmitter(ctx);
    };

    vehicleFacade.getCountryCodes();

    return {
      wrongPlateNumber,
      wrongCounryCode,
      wrongPlateNumberAndCounryCode,

      countryCode,
      plateNumber,
      validationRegExp,
      countries,

      checkPlateNumber,
      closeDialog,
    };
  },
});
