






































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  SetupContext,
  watch,
} from "@vue/composition-api";
import { vehicleFacade } from "@/store/modules/vehicle/vehicle.facade";
import { userFacade } from "@/store/modules/user/user.facade";
import { stickerFacade } from "@/store/modules/sticker/sticker.facade";
import VehicleAddForm from "@/components/vehicle/VehicleAddForm/VehicleAddForm.vue";
import CardSelector from "@/components/forms/CardSelector/CardSelector.vue";
import { HdCustomerVehicle } from "@/core/dataTypes/types";
import { tabFocusService } from "@/core/services/tabFocus.service";
import isEmpty from "lodash/fp/isEmpty";
import router from "@/router";
import { plateNumberValid } from "@/core/api/accountService.api";
import { i18n } from "@/locales/i18n";
import { DynamicObject } from "@/core/dataTypes/types/common.interface";
import { Subscription } from "rxjs";
import httpService from "@/core/services/http.service";

export default defineComponent({
  name: "StickerVehicleData",
  props: {
    isChooseNewVehicle: Boolean,
    step: Number,
  },
  /* eslint-disable @typescript-eslint/no-explicit-any */
  setup(props: any, { emit, refs }: SetupContext) {
    vehicleFacade.setPlateNumberLoading(false);
    const countryCodeErrorSubscription = new Subscription();
    const hasQuery = !isEmpty(router.app.$route.query);
    const notLoggedInUser = computed(userFacade.notLoggedInUser);
    const vehicleListLoading = computed(
      vehicleFacade.hdCustomerVehicleListLoading
    );
    const isBus = ref(false);
    const vehicleList = computed(vehicleFacade.hdCustomerVehicleList);
    const hdVehicleSelect = ref({} as any);
    const hdVehicleNew = reactive({
      countryCode: "",
      plateNumber: "",
      hdVehicleCategoryId: "",
    });
    const vehicleFormSubmitLoading = computed(
      vehicleFacade.vehicleFormSubmitLoading
    );
    const onChooseNewVehicle = (value: boolean) => {
      emit("onChooseNewVehicle", value);
      if (!value) {
        hdVehicleNew.countryCode = "";
        hdVehicleNew.plateNumber = "";
        hdVehicleNew.hdVehicleCategoryId = "";
      }
    };
    const nextStep = () => {
      if (props.isChooseNewVehicle) {
        vehicleFacade.setVehicleFormSubmitLoading(true);
        validatePlateNumber().then((valid) => {
          if (valid) {
            setVehicle();
          }
        });
      } else {
        setVehicle();
      }
    };
    const selectionChanged = () => {
      isBus.value = false;
    };
    const onCarChange = () => {
      isBus.value = false;
    };
    const setVehicle = () => {
      tabFocusService.setFocus("nuszBack");
      stickerFacade.saveStickerShopCart({
        hdVehicle: {
          ...(props.isChooseNewVehicle || !vehicleList.value?.length
            ? hdVehicleNew
            : hdVehicleSelect.value),
        },
      });
      emit("changeStep", 2);
    };

    watch(
      () => vehicleList.value,
      (newValue) => {
        if (!hasQuery) {
          emit("onChooseNewVehicle", !newValue?.length);
        }
        hdVehicleSelect.value = newValue?.find(
          (item) => item.isDefault
        ) as HdCustomerVehicle;
        isBus.value = hdVehicleSelect.value?.hdVehicleCategoryId === "B2";
      }
    );

    countryCodeErrorSubscription.add(
      httpService.getCountryCodeError().subscribe(() => {
        const errorObject: DynamicObject = {};
        errorObject[i18n.tc("VEHICLE_NEW_PAGE.FIELDS.COUNTRY_CODE")] = i18n.tc(
          "HTTP_ERRORS.INVALID_COUNTRY_CODE"
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (refs.formRef as any).setErrors(errorObject);
      })
    );
    const onPlateNumberValidationBlurred = () => {
      if (hdVehicleNew.plateNumber) {
        vehicleFacade.setPlateNumberLoading(true);
        validatePlateNumber();
      }
    };

    const validatePlateNumber = (): Promise<boolean> => {
      resetErrors();
      return plateNumberValid({
        plateNumber: hdVehicleNew.plateNumber,
        countryCode: hdVehicleNew.countryCode,
      })
        .then((response) => {
          vehicleFacade.setPlateNumberLoading(false);
          vehicleFacade.setVehicleFormSubmitLoading(false);
          if (response.validationResult === "INVALID_PLATE_NUMBER") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (refs.formRef as any).setErrors(setErrorObject(true));
            return false;
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (refs.formRef as any).setErrors(setErrorObject(false));
            return true;
          }
        })
        .catch((error) => {
          vehicleFacade.setPlateNumberLoading(false);
          vehicleFacade.setVehicleFormSubmitLoading(false);
          throw Error(error);
        });
    };

    const resetErrors = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (refs.formRef as any).setErrors(setErrorObject(false));
    };

    const setErrorObject = (valid: boolean): DynamicObject => {
      const errorObject: DynamicObject = {};
      errorObject[i18n.tc("VEHICLE_NEW_PAGE.FIELDS.PLATE_NUMBER")] = valid
        ? i18n.tc("VALIDATION.REGEX", 0, {
            name: i18n.tc("VEHICLE_NEW_PAGE.FIELDS.PLATE_NUMBER"),
          })
        : null;
      return errorObject;
    };

    onMounted(() => {
      if (hasQuery && props.step === 1) {
        emit("deleteQueryParam");
        emit("onChooseNewVehicle", true);
      }
    });

    onUnmounted(() => {
      countryCodeErrorSubscription.unsubscribe();
    });

    return {
      nextStep,
      onChooseNewVehicle,
      notLoggedInUser,
      hdVehicleNew,
      hdVehicleSelect,
      vehicleList,
      isBus,
      vehicleListLoading,
      vehicleFormSubmitLoading,
      onPlateNumberValidationBlurred,
      onCarChange,
      selectionChanged,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components: {
    VehicleAddForm,
    CardSelector,
  },
});
