



































































import { defineComponent } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";

export default defineComponent({
  name: "ValidityOverlapDialog",
  components: {
    BaseDialog,
  },
  props: {
    dialogProps: Object,
  },
  setup(props, ctx) {
    const stickerOverlaps = props.dialogProps?.overlaps;
    const closeDialog = () => {
      dialogCloseEmitter(ctx);
    };

    const add = () => {
      ctx.emit("result", { autoClose: true, payload: "ADD" });
    };

    return {
      add,
      closeDialog,
      stickerOverlaps,
    };
  },
});
